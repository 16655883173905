import { reactive, toRefs } from 'vue'
import pictures from '@/mocks/pictures.json'


const state = reactive({
  pictures: [],
  types: []
})

export default function() {
  const fetchPictures = () => {
    state.pictures = pictures
    state.types = pictures.reduce((a, b) => a.includes(b.type) ? a : a.concat(b.type), [])
  }

  return {
    ...toRefs(state),
    fetchPictures
  }
}