<template>
  <div class="pictures">
    <div class="pictures-filters">
      <span :class="['filter', { 'active' : filter === '' }]" @click="() => filterClick()">
        {{ $t('pictures.all') }}
      </span>
      <span v-for="type in types"
        :key="`type-${type}`"
        :class="['filter', { 'active' : filter === type }]"
        @click="() => filterClick(type)">
        {{ $t(`pictures.types.${type}`) }}
      </span>
    </div>
    <div class="pictures-ctnr">
      <transition-group name="list">
        <span v-for="pic in filteredPictures" :key="`picture-${pic.url}`" class="picture" @click="() => imageClick(pic)">
          <img :src="require(`@/assets/images/pictures/${pic.type}/${pic.url}`)" :alt="pic.url" />
        </span>
      </transition-group>
    </div>
    <transition name="fade">
      <div class="modal-backdrop" @click.self="showModal = false" v-if="showModal">
        <div class="modal" @keydown.esc="showModal = false">
          <font-awesome-icon icon="times" size="2x" class="modal-close" @click="showModal = false" />
          <font-awesome-icon icon="chevron-left" size="3x" class="modal-chevron" @click="() => changeSelectedPicture(1)" />
          <img v-for="(pic, i) in filteredPictures"
            :key="`modal-picture-${pic.url}-${i}`"
            :src="require(`@/assets/images/pictures/${pic.type}/${pic.url}`)"
            :alt="pic.url"
            :class="['modal-picture', { 'active': selectedPicture === pic }]" />
          <font-awesome-icon icon="chevron-right" size="3x" class="modal-chevron" @click="() => changeSelectedPicture(-1)" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  import picturesStore from '@/store/pictures.store'

  export default {
    name: "Pictures",
    setup() {
      const filter = ref("")
      const showModal = ref(false)
      const selectedPicture = ref()
      const { pictures, types, fetchPictures } = picturesStore()
      fetchPictures()

      let filteredPictures = ref([])
      let filteredPicturesUrls = ref([])

      watch(() => filter, newFilter => {
        filteredPictures.value = pictures.value.filter(pic => newFilter.value === "" || pic.type === newFilter.value)
        filteredPicturesUrls.value = filteredPictures.value.map(pic => require(`@/assets/images/pictures/${pic.type}/${pic.url}`))
      }, {deep: true, immediate: true})

      const filterClick = (type = "") => filter.value = type
      const imageClick = (pic) => {
        selectedPicture.value = pic
        showModal.value = true
      }

      const changeSelectedPicture = increment => {
        const curIx = filteredPictures.value.findIndex(pic => pic === selectedPicture.value)
        let nextIx = curIx + increment
        if (nextIx < 0) nextIx = filteredPictures.value.length - 1
        if (nextIx > filteredPictures.value.length - 1) nextIx = 0
        selectedPicture.value = filteredPictures.value[nextIx]
      }

      window.addEventListener('keyup', function(event) {
        if (showModal) {
          switch(event.code) {
            case 'ArrowLeft':
              changeSelectedPicture(-1)
              break
            case 'ArrowRight':
              changeSelectedPicture(1)
              break
            case 'Escape':
              showModal.value = false
              break
            default:
              break
          }
        }
      })
      
      return { filteredPictures, types, filter, filterClick, filteredPicturesUrls, selectedPicture, showModal, imageClick, changeSelectedPicture }
    }
  }
</script>

<style lang="scss" scoped>
  .pictures {
    padding: 1rem;

    &-filters {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      column-gap: 1rem;
      row-gap: 1rem;

      .filter {
        padding: 0.5rem 1rem;
        cursor: pointer;
        border: 1px solid var(--main-color-accent);
        border-radius: 0.5rem;

        &.active, &:hover {
          background: var(--main-color-accent-light);
        }
      }
    }

    &-ctnr {
      display: flex;
      flex-wrap: wrap;
      column-gap: 1rem;
      row-gap: 1rem;
      padding: 2rem 0;
      overflow-y: hidden;
      justify-content: space-between;

      .list-enter-from {
        opacity: 0;
        transform: scale(0.6);
      }
      .list-enter-active {
        transition: all 0.3s linear;
        transition-delay: 0.3s;
      }
      .list-leave-to {
        opacity: 0;
        transform: scale(0.6);
      }
      .list-leave-active {
        transition: all 0.3s linear;
      }
      .list-move {
        transition: all 0.3s linear;
      }

      .picture {
        width: 30%;

        img {
          width: 100%;
          height: 15rem;
          object-fit: cover;
          cursor: pointer;
          border-radius: 0.5rem;

          &:hover {
            transform: scale(1.01)
          }
        }
      }
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
      opacity: 0;
    }

    .modal-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(5px);
      overflow-y: auto;

      .modal {
        margin: 1rem auto;
        padding: 1rem;
        max-width: 50rem;
        background: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: var(--contrast-color) 0px 5px 15px;
        border-radius: 1rem;
        position: relative;

        &-close {
          position: absolute;
          top: 1rem;
          right: 1rem;
          cursor: pointer;
        }

        &-chevron {
          cursor: pointer;
        }

        &-picture {
          display: none;
          max-width: 80%;
          max-height: 40rem;

          &.active {
            display: block;
          }
        }
      }
    }
  }

  @include mobile {
    .pictures {
      &-ctnr {
        .picture {
          width: 45%;
        }
      }
    }
  }

  @include sm-mobile {
    .pictures {
      &-ctnr {
        .picture {
          width: 100%;
        }
      }
    }
  }
</style>